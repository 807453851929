import { combineReducers } from "redux"
import { reducer as formReducer } from "redux-form"
import { routerReducer as router } from "react-router-redux"
import { reducer as notifications } from "react-notification-system-redux"
import { i18nState } from "redux-i18n"
import { reducer as permissions } from "react-redux-permissions"
import general from "./general"
import alerts from "./alerts/list"
import signUp from "./user/signUp"
import user from "./user/user"
import trialStart from "./user/trial"
import multichannelOnboarding from "./user/multichannelOnboarding"
import ebayOnboarding from "./user/ebayOnboarding"
import cdiscountOnboarding from "./user/cdiscountOnboarding"
import accountsList from "./accounts/list"
import account from "./accounts/single"
import settings from "./user/settings"
import businessType from "./user/businessType"
import dashboardInventory from "./dashboard/inventory"
import dashboardSales from "./dashboard/sales"
import productsList from "./products/list"
import productSummary from "./product/summary"
import productPrices from "./product/prices"
import productTranslations from "./product/translations"
import productSettings from "./products/settings"
import productBulk from "./products/bulk"
import ordersList from "./orders/list"
import conversionChartsList from "./conversionCharts/list"
import conversionChartsBrands from "./conversionCharts/brands"
import conversionChartsCategories from "./conversionCharts/categories"
import conversionChart from "./conversionCharts/single"
import repricerSettings from "./repricer/settings"
import blacklist from "./blacklist/list"
import blacklistSingle from "./blacklist/single"
import translationManager from "./translationManager/list"
import sellerTerm from "./translationManager/sellerTerm"
import shippingProfilesDefault from "./shippingProfiles/default"
import shippingProfilesList from "./shippingProfiles/list"
import shippingProfile from "./shippingProfiles/single"
import shippingProfilesServices from "./shippingProfiles/services"
import priceAdjustmentRules from "./priceAdjustment/rules"
import priceAdjustmentSingleRule from "./priceAdjustment/rule"
import profileLoginPassword from "./profile/loginPassword"
import profileContactDetails from "./profile/contactDetails"
import profileFinancialDetails from "./profile/financialDetails"
import profileCreditCardConfigDetails from "./profile/creditCardConfigDetails"
import profileThirdPartyTools from "./profile/thirdPartyTools"
import profileUpsell from "./profile/upsell"
import plans from "./plans/list"
import invoices from "./invoices"
import zendeskArticles from "./zendesk/articles"
import zendesk from "./zendesk/zendesk"
import epl from "./epl/epl"
import eplAlerts from "./epl/eplAlerts"
import eplCampaign from "./epl/campaign"
import eplDashboardReport from "./epl/eplDashboardReport"
import eplExcludingItems from "./epl/eplExcludingItems"
import eplMarkets from "./epl/eplMarkets"
import eplModals from "./epl/eplModals"
import eplReport from "./epl/eplReport"
import entitlements from "./entitlements/entitlements"
import surveys from "./surveys/surveys"

// Combine reducers with routeReducer which keeps track of
// router state
const rootReducer = combineReducers({
  router,
  i18nState,
  form: formReducer,
  notifications,
  permissions,
  // OVERALL
  general,
  alerts,
  user,
  settings,
  // DASHBOARD
  dashboardInventory,
  dashboardSales,
  // PRODUCTS
  productsList,
  productSettings,
  productBulk,
  // PRODUCT
  productSummary,
  productPrices,
  productTranslations,
  // ORDERS
  ordersList,
  // CONVERSION CHARTS
  conversionChartsList,
  conversionChartsBrands,
  conversionChartsCategories,
  // CONVERSION CHART
  conversionChart,
  // REPRICER
  repricerSettings,
  // BLACKLIST
  blacklist,
  blacklistSingle,
  // TRANSLATION MANAGER
  translationManager,
  sellerTerm,
  // SHIPPING PROFILES
  shippingProfilesDefault,
  shippingProfilesList,
  shippingProfile,
  shippingProfilesServices,
  // PRICE ADJUSTMENT
  priceAdjustmentRules,
  priceAdjustmentSingleRule,
  // USER
  signUp,
  trialStart,
  multichannelOnboarding,
  cdiscountOnboarding,
  ebayOnboarding,
  businessType,
  // PROFILE
  profileLoginPassword,
  profileContactDetails,
  profileFinancialDetails,
  profileCreditCardConfigDetails,
  profileThirdPartyTools,
  profileUpsell,
  // ACCOUNTS
  account,
  accountsList,
  // PLANS
  plans,
  // INVOICES
  invoices,
  // ZENDESK
  zendesk,
  zendeskArticles,
  // EPL
  epl,
  eplAlerts,
  eplCampaign,
  eplDashboardReport,
  eplExcludingItems,
  eplMarkets,
  eplModals,
  eplReport,
  // ENTITLEMENTS
  entitlements,
  // SURVEYS
  surveys,
})

export default rootReducer
