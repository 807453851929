import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Switch, Route, Redirect } from "react-router"
import { connect } from "react-redux"
import Loadable from "react-loadable"
import Notifications from "react-notification-system-redux"
import ReactTooltip from "react-tooltip"
import { setLanguage } from "redux-i18n"
import Cookies from "js-cookie"
import { get } from "lodash"

import { getUserLanguages } from "shared/services/languages"
import openZendeskPlugin from "shared/services/zendesk/plugin"
import GlobalLoader from "shared/components/Loader/Global"
import Loader from "shared/components/Loader/Local"
import AlertBanner from "shared/components/AppAlertBanner"
import Header from "shared/components/Header"
import CheckoutHeader from "shared/components/Header/Checkout"
import SignUpHeader from "shared/components/Header/SignUp"
import Footer from "shared/components/Footer"
import Throw from "shared/components/Throw"
import GlobalModal from "shared/views/GlobalModal"

import CONFIG from "core/config"

import { get as fetchUser } from "data/actions/user/auth"

import Meta from "./Meta"
import ErrorBoundary from "./ErrorBoundary"
import Auth from "./Auth"
import PlatformAccountRoot from "./Root"
import FallbackComponent from "./FallbackComponent"
import TokenConnectionHandler from "./NewAccount/Amazon/components/TokenConnectionHandler"
import Services from "./Services"

import GetFeedbackSurveys from "./GetFeedbackSurveys/GetFeedbackSurveys"
import styles from "./App.module.css"

import "../shared/styles/Base.css"

const isChineseSite = CONFIG.location === "cn"

const NewEbayEsbAccount = Loadable({
  loader: () => import("./NewAccount/eBayESB"),
  loading: () => <Loader />,
})
const Checkout = Loadable({
  loader: () => import("./Checkout"),
  loading: () => <Loader />,
})
const Payment = Loadable({
  loader: () => import("./Payment"),
  loading: () => <Loader />,
})
const TransactionPendingView = Loadable({
  loader: () => import("./Checkout/views/Confirmation/views/LoaderView"),
  loading: () => <Loader />,
})
const User = Loadable({
  loader: () => import("./User"),
  loading: () => <Loader />,
})
const Token = Loadable({
  loader: () => import("./Token"),
  loading: () => <Loader />,
})
const Help = isChineseSite
  ? Loadable({
      loader: () => import("./Help"),
      loading: () => <Loader />,
    })
  : null
const SignUp = Loadable({
  loader: () => import("./SignUp"),
  loading: () => <Loader />,
})
const EmailForm = Loadable({
  loader: () => import("./PasswordReset/views/EmailForm"),
  loading: () => <Loader />,
})
const NewPasswordForm = Loadable({
  loader: () => import("./PasswordReset/views/NewPasswordForm"),
  loading: () => <Loader />,
})
const TermsAndConditions = Loadable({
  loader: () => import("./TermsAndConditions"),
  loading: () => <Loader />,
})

const NewAccount = Loadable({
  loader: () => import("./NewAccount"),
  loading: () => <Loader />,
})

class App extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    notifications: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    shouldStartEbayOnboarding: PropTypes.bool.isRequired,
    general: PropTypes.object.isRequired,
    setInitialLang: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    hasOngoingOnboarding: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)

    const { history } = this.props

    this.state = {
      location: history.location,
    }

    this.fallbackRoutes = [
      "/dashboard/:platform?",
      "/products/:platform?/:tab?",
      "/orders/:platform?",
      "/tools/conversion-charts",
      "/tools/translation-manager",
      "/tools/blacklist/:platform?",
      "/tools/price-adjustment/:platform?",
      "/tools/shipping-manager",
      "/tools/bulk-request",
      "/tools/repricer",
      "/profile/accounts",
      "/profile",
      "/plans",
      "/ebay-promoted-listings",
      "/ebay-promoted-listings-settings",
      "/cdiscount-dashboard",
      "/cdiscount-onboarding",
      "/amazon-onboarding",
      "/ebay-onboarding",
    ]
  }

  componentDidMount() {
    const { history, setInitialLang, getUser } = this.props
    const initialLang = getUserLanguages()
    setInitialLang(initialLang)
    getUser().catch(() => {})

    // Check if we come from some previous page before login
    const redirectTo = Cookies.get("redirectTo")
    if (redirectTo) {
      Cookies.remove("redirectTo")
      history.replace(redirectTo)
    }
  }

  notSignedInRoutes() {
    const { general } = this.props
    return general.loading ? (
      <GlobalLoader />
    ) : (
      <div>
        {!isChineseSite && (
          <Route
            path="/submit-request/:topic?/:view?"
            render={() => {
              openZendeskPlugin()
              return null
            }}
          />
        )}
        <Switch>
          {isChineseSite && (
            <Route path="/submit-request/:topic?/:view?" component={Help} />
          )}
          <Route
            exact
            path="/terms-and-conditions/wi/:version(current|previous)"
            component={TermsAndConditions}
          />
          <Route
            exact
            path="/terms-and-conditions/wi"
            component={TermsAndConditions}
          />
          <Redirect
            from="/terms-and-conditions"
            to="/terms-and-conditions/wi"
          />
          <Route path="/sign-up" component={SignUp} />
          <Route path="/password-reset/submit-email" component={EmailForm} />
          <Route
            path="/password-reset/submit-password"
            component={NewPasswordForm}
          />
          <Route component={Auth} />
        </Switch>
      </div>
    )
  }

  signedInRoutes() {
    const {
      general,
      shouldStartEbayOnboarding,
      user,
      wiShippingEnabled,
      wiReturnsEnabled,
      isShippingEligible,
      isReturnsEligible,
    } = this.props
    return (
      <div>
        {!isChineseSite && (
          <Route
            path="/submit-request/:topic?/:view?"
            render={() => {
              openZendeskPlugin()
              return null
            }}
          />
        )}

        <Switch>
          {shouldStartEbayOnboarding && (
            <Route path="/onboarding" component={NewEbayEsbAccount} />
          )}
          <Route
            path="/:service(shipping|returns)"
            render={renderProps => (
              <Services
                userId={user.id}
                wiShippingEnabled={wiShippingEnabled}
                wiReturnsEnabled={wiReturnsEnabled}
                isReturnsEligible={isReturnsEligible}
                isShippingEligible={isShippingEligible}
                {...renderProps}
              />
            )}
          />

          <Route path="/user" component={User} />
          <Route
            exact
            path="/terms-and-conditions/:type(wi|shipping)/:version(current|previous)"
            component={TermsAndConditions}
          />
          <Route
            exact
            path="/terms-and-conditions/:type(wi|shipping)"
            component={TermsAndConditions}
          />
          <Redirect
            from="/terms-and-conditions"
            to="/terms-and-conditions/wi"
          />
          <Route path="/checkout/pending" component={TransactionPendingView} />
          <Route path="/checkout/:plan" component={Checkout} />
          <Route path="/payment/:type" component={Payment} />
          {isChineseSite && (
            <Route path="/submit-request/:topic?/:view?" component={Help} />
          )}
          <Route path="/token" component={Token} />
          <Route
            path="/profile/accounts/new-token"
            render={({ location }) => (
              <Redirect to={`/token${location.search}`} />
            )}
          />
          <Route
            path="/:platform(all|ebay|amazon|cdiscount)/:account"
            component={PlatformAccountRoot}
          />
          {this.fallbackRoutes.map(route => (
            <Route key={route} path={route} component={FallbackComponent} />
          ))}
          <Redirect
            to={
              shouldStartEbayOnboarding
                ? "/onboarding"
                : `/${general.activePlatform}/${general.activeAccount}/dashboard`
            }
          />
        </Switch>
      </div>
    )
  }

  renderCommon() {
    const { general } = this.props

    return general.stateReady ? this.signedInRoutes() : this.notSignedInRoutes()
  }

  renderOnboarding() {
    const { general } = this.props
    return general.loading ? (
      <GlobalLoader />
    ) : (
      <Switch>
        <Route path="/start" component={NewAccount} />
        <Route path="/user" component={User} />
        <Route
          exact
          path="/terms-and-conditions/:type(wi|shipping)/:version(current|previous)"
          component={TermsAndConditions}
        />
        <Route
          exact
          path="/terms-and-conditions/:type(wi|shipping)"
          component={TermsAndConditions}
        />
        <Redirect from="/terms-and-conditions" to="/terms-and-conditions/wi" />
        <Redirect to="/start" />
      </Switch>
    )
  }

  render() {
    const { general, user, notifications, hasOngoingOnboarding } = this.props
    const { location } = this.state

    return (
      <ErrorBoundary>
        <Throw shouldThrow={general.errorLoadingInitialData} />
        <Meta />
        <AlertBanner />
        <TokenConnectionHandler location={location} />
        <Switch>
          <Route
            path="/checkout/:plan/(billing|payment)"
            component={CheckoutHeader}
          />
          <Route path="/(sign-up|start)" component={SignUpHeader} />
          <Route
            render={() => (
              <Header
                stateReady={general.stateReady}
                name={user.name}
                email={user.email}
              />
            )}
          />
        </Switch>
        <div className={styles.root}>
          {user.isPlatformless &&
          !user.isDeleted &&
          !user.chosenOnboardingPlatform &&
          !hasOngoingOnboarding
            ? this.renderOnboarding()
            : this.renderCommon()}
          <GetFeedbackSurveys />
        </div>
        <Route component={GlobalModal} />
        <Footer />
        <Notifications notifications={notifications} allowHTML />
        <ReactTooltip effect="solid" />
        {general.overlay && (
          <div className={styles.overlay}>
            <div className={styles.overlayContent}>
              {general.overlayMessage}
            </div>
          </div>
        )}
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = state => ({
  notifications: state.notifications,
  general: state.general,
  user: state.user,
  hasOngoingOnboarding: get(
    state.multichannelOnboarding,
    "current.is_ongoing",
    false
  ),
  shouldStartEbayOnboarding: state.ebayOnboarding.shouldBeOnboarded,
  wiShippingEnabled: state.settings.data["wi-shipping-enabled"],
  wiReturnsEnabled: state.settings.data["wi-returns-enabled"],
  isReturnsEligible:
    state.entitlements.can_onboard_to_returns_domestic ||
    state.entitlements.can_onboard_to_returns_international_active,
  isShippingEligible: state.entitlements.can_use_shipping,
})

const mapDispatchToProps = dispatch => ({
  getUser: () => dispatch(fetchUser()),
  setInitialLang: lang => dispatch(setLanguage(lang)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
