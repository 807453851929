import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { get } from "lodash"
import CONFIG from "core/config"
import { getUserLanguages } from "shared/services/languages"
import { isEligibleForAmazonAdoption } from "shared/services/amazonAdoption"

const ALLOWED_SENTRY_ENV = ["testenv", "production"]
const isSentryEnvAllowed = env => ALLOWED_SENTRY_ENV.includes(env)

const LANG_MODES = {
  DE: 1,
  EN: 2,
  FR: 12,
  ZH: 13,
  IT: 14,
  ES: 15,
}

export const injectLiveChat = (user = {}) => {
  if (CONFIG.env !== "production" || CONFIG.location !== "cn") return
  window.__lc = {}
  const lang = (getUserLanguages() || "EN").toUpperCase()
  const __lc = {}
  let lc = null
  let s = null

  if (user.id) {
    __lc.visitor = {
      name: user.name,
      email: user.email,
    }
  }

  __lc.license = 3985091
  __lc.group = LANG_MODES[lang]

  window.__lc = __lc

  lc = document.createElement("script")
  lc.type = "text/javascript"
  lc.async = true
  lc.src = `${
    document.location.protocol === "https:" ? "https://" : "http://"
  }cdn.livechatinc.com/tracking.js`
  s = document.getElementsByTagName("script")[0] // eslint-disable-line
  s.parentNode.insertBefore(lc, s)

  // hacking LiveChat
  setTimeout(() => {
    const LC_API = window.LC_API || {}
    LC_API.on_after_load = () => {
      LC_API.hide_chat_window()
    }
    LC_API.on_chat_window_minimized = () => {
      LC_API.hide_chat_window()
    }
    LC_API.on_chat_window_maximized = () => {
      LC_API.show_chat_window()
    }
  }, 1000)
}

export const extendTrackingData = (user = {}, i18nState = {}) => {
  if (!user.id || !isSentryEnvAllowed(CONFIG.env)) return

  Sentry.setUser({
    id: user.id,
    email: user.email,
    username: user.name,
  })

  if (CONFIG.env === "production") {
    let userState = ""
    if (user.isESBTrial) {
      userState = "ESBTrial"
    } else if (user.isTrial) {
      userState = "RegularTrial"
    } else if (user.isPaying) {
      userState = "Paying"
    } else if (user.isDeleted) {
      userState = "Deleted"
    }

    window.dataLayer.push({
      userId: user.id,
      email: user.email,
      name: user.name,
      sui_lang: i18nState.lang,
      rtp_campaign: isEligibleForAmazonAdoption(user.id) ? "amazon" : "",
      user_state: userState,
      event: "mkt_connection",
      early_birds: get(user, "special_offers.early_birds.value", false),
      currency: user.currency,
    })
  }
}

export const startSentryJS = () => {
  if (!isSentryEnvAllowed(CONFIG.env)) return

  Sentry.init({
    dsn:
      "https://98b4c12163db451b95427eb5f3e07a6f@o439982.ingest.sentry.io/5407860",
    environment: CONFIG.env,
    release: CONFIG.release,
    integrations: [
      // new BrowserTracing({
      //   tracePropagationTargets: [],
      // }),
      new BrowserTracing(),
    ],
    tracesSampleRate: 1,
    denyUrls: [
      "devel.webinterpret.com",
      "pages.webinterpret.com",
      // Marketo
      "snrtp-cdn.marketo.com",
      "snrtp1.marketo.com",
      "rtp-static.marketo.com",
      "munchkin.marketo.net",
      "app-lon02.marketo.com",
      // Live Chat
      "livechatinc.com",
      // Facebook flakiness
      "facebook.com",
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Firefox extensions
      /^moz-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    ignoreErrors: [
      "Unauthorized access",
      "Failed to fetch",
      "Non-Error exception captured",
      "Non-Error promise rejection captured",
      // Marketo
      "MktoForms2 is not defined",
      // Random plugins/extensions
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Facebook borked
      "fb_xd_fragment",
      "fbq is not defined",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",
    ],
  })
}

export default {
  startSentryJS,
  injectLiveChat,
  extendTrackingData,
}
