import {
  all,
  select,
  put,
  fork,
  call,
  take,
  putResolve,
} from "redux-saga/effects"

import {
  getAccounts,
  getUserOnboarding,
  getAllMarkets,
  getDashboardReportData,
} from "data/actions/epl/epl"

import {
  DASHBOARD_GET,
  DASHBOARD_CANCEL,
  DASHBOARD_GET_SALES_WEEKLY,
  DASHBOARD_GET_SALES_WEEKLY_REQUEST,
  DASHBOARD_GET_SALES_WEEKLY_SUCCESS,
  DASHBOARD_GET_SALES_WEEKLY_FAILURE,
  DASHBOARD_GET_SALES_MONTHLY,
  DASHBOARD_GET_SALES_MONTHLY_REQUEST,
  DASHBOARD_GET_SALES_MONTHLY_SUCCESS,
  DASHBOARD_GET_SALES_MONTHLY_FAILURE,
  DASHBOARD_GET_INVENTORY,
  DASHBOARD_GET_INVENTORY_REQUEST,
  DASHBOARD_GET_INVENTORY_SUCCESS,
  DASHBOARD_GET_INVENTORY_FAILURE,
  EPL_DASHBOARD_REPORT_DATA_LOAD_REQUEST,
  EPL_DASHBOARD_REPORT_DATA_LOAD_SUCCESS,
  EPL_DASHBOARD_REPORT_DATA_LOAD_FAILURE,
} from "../constants"

import { getWeekly, getMonthly } from "../fetch/dashboard/sales"
import { get as getInventoryData } from "../fetch/dashboard/inventory"

function* getSalesWeekly() {
  while (true) {
    const action = yield take([DASHBOARD_GET, DASHBOARD_GET_SALES_WEEKLY])
    const { user } = yield select()
    yield put({ type: DASHBOARD_GET_SALES_WEEKLY_REQUEST })
    try {
      const { data } = yield call(() => getWeekly(user, action.platform))
      if (data) {
        yield put({ type: DASHBOARD_GET_SALES_WEEKLY_SUCCESS, data: data.data })
      }
    } catch (error) {
      yield put({ type: DASHBOARD_GET_SALES_WEEKLY_FAILURE, error })
    }
  }
}

function* getSalesMonthly() {
  while (true) {
    const action = yield take([DASHBOARD_GET, DASHBOARD_GET_SALES_MONTHLY])
    yield put({ type: DASHBOARD_GET_SALES_MONTHLY_REQUEST })
    const { user } = yield select()
    try {
      const { data } = yield call(() => getMonthly(user, action.platform))
      if (data) {
        let sales = data
        if (action.platform === "ebay") {
          sales = data.data
        }
        yield put({ type: DASHBOARD_GET_SALES_MONTHLY_SUCCESS, data: sales })
      }
    } catch (error) {
      yield put({ type: DASHBOARD_GET_SALES_MONTHLY_FAILURE, error })
    }
  }
}

function* getInventory() {
  while (true) {
    const action = yield take([DASHBOARD_GET, DASHBOARD_GET_INVENTORY])
    const { user } = yield select()
    yield put({ type: DASHBOARD_GET_INVENTORY_REQUEST })
    try {
      const { data } = yield call(() => getInventoryData(user, action.platform))
      if (data) {
        let inventory = data
        if (action.platform === "ebay") {
          inventory = data.data
        }
        yield put({ type: DASHBOARD_GET_INVENTORY_SUCCESS, data: inventory })
      }
    } catch (error) {
      yield put({ type: DASHBOARD_GET_INVENTORY_FAILURE, error })
    }
  }
}

function* getEPLData() {
  while (true) {
    yield take([DASHBOARD_GET])
    yield put({ type: EPL_DASHBOARD_REPORT_DATA_LOAD_REQUEST })
    try {
      yield putResolve(getUserOnboarding())
      yield putResolve(getAccounts())
      yield all([
        putResolve(getAllMarkets()),
        putResolve(getDashboardReportData()),
      ])
      yield put({ type: EPL_DASHBOARD_REPORT_DATA_LOAD_SUCCESS })
    } catch (error) {
      if ([424, 500, 502, 504].includes(error.status)) {
        yield put({ type: EPL_DASHBOARD_REPORT_DATA_LOAD_FAILURE })
      } else {
        yield put({ type: EPL_DASHBOARD_REPORT_DATA_LOAD_SUCCESS })
      }
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([
      fork(getSalesWeekly),
      fork(getSalesMonthly),
      fork(getInventory),
      fork(getEPLData),
    ])

    yield all(tasks)
    yield take(DASHBOARD_CANCEL)
    yield all(tasks.map(task => task.cancel()))
  }
}
