import React from "react"
import PropTypes from "prop-types"

import { useTranslate } from "context/translateContext"
import Container from "../Container"

import CheckoutSteps from "./components/CheckoutSteps"

import styles from "./Header.module.css"
import logo from "../../images/wi-logo.svg"

const CheckoutHeader = ({ location }) => {
  const { t: translate } = useTranslate()
  return (
    <header className={styles.root}>
      <Container>
        <div className="row">
          <div className="col-md-3">
            <div className={styles.logo}>
              <img src={logo} alt="WebInterpret logo" />
            </div>
          </div>
          <div className="col-md-6 hidden-xs hidden-sm textCenter">
            <CheckoutSteps pathname={location.pathname} />
          </div>
          <div className="col-md-3 hidden-xs textRight">
            <span
              className={[styles.text, "textUppercase", "w500", "fs-12"].join(
                " "
              )}
            >
              {translate("Secure checkout")} <i className="icon-lock" />
            </span>
          </div>
        </div>
      </Container>
    </header>
  )
}

CheckoutHeader.propTypes = {
  location: PropTypes.object.isRequired,
}

export default CheckoutHeader
