import "babel-polyfill"
import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { ConnectedRouter } from "react-router-redux"
import FastClick from "fastclick"
import I18n from "redux-i18n"

import config from "core/config"
import history from "core/history"
import configureStore from "data/configureStore"
import { startSentryJS } from "shared/services/analytics"

import { TranslateProvider } from "context/translateContext"

import App from "views/App"

startSentryJS()

const store = configureStore({
  i18nState: {
    lang: "en",
    translations: {},
  },
})

// Make taps on links and buttons work fast on mobiles
FastClick.attach(document.body)

const container = document.getElementById("app")
const root = createRoot(container)

const renderClient = () => {
  root.render(
    <Provider store={store}>
      <I18n translations={{}} fallbackLang="en" useReducer>
        <TranslateProvider store={store}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </TranslateProvider>
      </I18n>
    </Provider>
  )
}

// Render the client-side DOM
renderClient()

// Enable Hot Module Replacement (HMR)
if (config.env !== "production" && module.hot) {
  module.hot.accept("./views/App", () => {
    renderClient()
  })
}
