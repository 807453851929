import { isNil } from "lodash"

export const getCurrencyLocaleValue = ({
  value,
  currency,
  language,
  minimumFractionDigits = 2,
}) =>
  Intl.NumberFormat(language, {
    style: "currency",
    currency,
    minimumFractionDigits,
  }).format(value)

export const getPercentageLocaleValue = ({ value, language }) =>
  Intl.NumberFormat(language, {
    style: "percent",
    maximumFractionDigits: 2,
  }).format(value)

export const getUnformattedValue = (value, decimalSeparator) => {
  let unformattedValue = value
  if (value !== "" && !isNil(value)) {
    const regexOnlyDigitsAndSeparator =
      decimalSeparator === "." ? /[^0-9.]/g : /[^0-9,]/g
    const regexDoubleSeparator = decimalSeparator === "." ? /\.\./g : /,,/g

    if (typeof value === "string") {
      unformattedValue = unformattedValue.replace(
        regexOnlyDigitsAndSeparator,
        ""
      )
      unformattedValue = unformattedValue.replace(
        regexDoubleSeparator,
        decimalSeparator
      )
    }
  }
  return unformattedValue
}

export const getInternationalFloatValue = (
  value,
  decimalSeparator,
  language
) => {
  const unformattedValue = getUnformattedValue(value, decimalSeparator)
  if (unformattedValue === "") return ""
  const regexEndsWithSeparator = decimalSeparator === "." ? /\.$/ : /,$/
  const regexEndsWithSeparatorAndZero =
    decimalSeparator === "." ? /\.0$/ : /,0$/
  const regexEndsWithSeparatorAndDoubleZero =
    decimalSeparator === "." ? /\.00$/ : /,00$/
  const regexEndsWithSeparatorOneDigitAndZero =
    decimalSeparator === "." ? /\.\d0$/ : /,\d0$/

  let internationalValue = Intl.NumberFormat(language, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(parseFloat(unformattedValue.replace(",", ".")))
  if (unformattedValue.match(regexEndsWithSeparator)) {
    internationalValue += decimalSeparator
  }
  if (unformattedValue.match(regexEndsWithSeparatorAndZero)) {
    internationalValue = `${internationalValue + decimalSeparator}0`
  }

  if (unformattedValue.match(regexEndsWithSeparatorOneDigitAndZero)) {
    if (unformattedValue.match(regexEndsWithSeparatorAndDoubleZero)) {
      internationalValue = `${internationalValue + decimalSeparator}00`
    } else {
      internationalValue = `${internationalValue}0`
    }
  }

  return internationalValue
}

export const getInternationalCurrencyValue = (value, language) =>
  isNil(value) || value === ""
    ? value
    : Intl.NumberFormat(language, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value)

export const getDecimalSeparator = locale => {
  const numberWithDecimalSeparator = 1.1
  return Intl.NumberFormat(locale)
    .formatToParts(numberWithDecimalSeparator)
    .find(part => part.type === "decimal").value
}

export default {
  getCurrencyLocaleValue,
  getUnformattedValue,
  getInternationalFloatValue,
  getInternationalCurrencyValue,
  getDecimalSeparator,
}
