import React from "react"
import { withRouter } from "react-router"
import PropTypes from "prop-types"

import { useTranslate } from "context/translateContext"
import CONFIG from "core/config"

import Button from "shared/components/Button/Button"
import Container from "shared/components/Container"
import Panel from "shared/components/Panel"
import getPlatformContent from "shared/services/upsell/platformUpsell"
import handleOpen from "views/User/views/PlansNew/services/zendeskPopupOpener"
import { SERVICE_TYPE } from "views/Services/helpers/serviceEligibility"

import styles from "./ServiceUpsell.module.css"

const RETURNS = "returns"
const NOT_SET = "notSet"

const getContactContent = option => {
  const translate = string => string

  switch (option) {
    case "returnsComingSoon":
      return (
        <div className="fs-16 m-t-20">
          {translate("Please")}{" "}
          <Button
            variation="link"
            onClick={handleOpen}
            className={styles.contactUsButtonSmall}
          >
            {translate("contact us")}
          </Button>{" "}
          {translate(
            "if you have a return address in one of these countries and you can start with Returns already or if you want to be notified when we bring Returns to your country"
          )}
        </div>
      )
    case "shippingComingSoon":
      return (
        <div className="fs-16 m-t-20">
          {translate("Please")}{" "}
          <Button
            variation="link"
            onClick={handleOpen}
            className={styles.contactUsButtonSmall}
          >
            {translate("contact us")}
          </Button>{" "}
          {translate(
            "if you have a warehouse in one of these countries and you can start with Shipping already or if you want to be notified when we bring Shipping to your country"
          )}
        </div>
      )
    case "returnsDemo":
      return (
        <div className="fs-16 m-t-20">
          {translate("Please")}{" "}
          <Button
            variation="link"
            onClick={handleOpen}
            className={styles.contactUsButtonSmall}
          >
            {translate("contact us")}
          </Button>{" "}
          {translate(
            "if you want to learn how Webinterpret Returns work for your domestic and international listings."
          )}
        </div>
      )
    default:
      return (
        <div className="fs-20 m-t-20">
          {translate("Need more information?")}{" "}
          <Button
            variation="link"
            onClick={handleOpen}
            className={styles.contactUsButton}
          >
            {translate("Contact us")}
          </Button>
        </div>
      )
  }
}

const ServiceUpsell = props => {
  const { t: translate } = useTranslate()
  const {
    service,
    serviceEligibility,
    wiShippingEnabled,
    wiReturnsEnabled,
    isReturnsEligible,
    isShippingEligible,
  } = props

  const redirectToShippingUi = () => {
    window.location.replace(`${CONFIG.shippingHost}`)
  }
  const redirectToReturnsUi = () => {
    window.location.replace(`${CONFIG.shippingHost}/returns`)
  }
  let upsellOption = NOT_SET

  if (service === SERVICE_TYPE.SHIPPING) {
    if (wiShippingEnabled || isShippingEligible) {
      redirectToShippingUi()
    } else if (serviceEligibility) {
      upsellOption = ""
    } else {
      upsellOption = "comingSoon"
    }
  }

  if (service === RETURNS) {
    if (wiReturnsEnabled) {
      redirectToReturnsUi()
    } else if (serviceEligibility && !isReturnsEligible) {
      upsellOption = ""
    } else if (serviceEligibility && isReturnsEligible && !wiReturnsEnabled) {
      upsellOption = "demo"
    } else {
      upsellOption = "comingSoon"
    }
  }
  const {
    title,
    description,
    image,
    buttonLink,
    buttonText,
    isInsideLink,
    option,
  } = getPlatformContent(service, upsellOption)

  return (
    upsellOption !== NOT_SET && (
      <div className="clearfix">
        <Container>
          <Panel className="no-p-r p-t-60 p-b-60 p-l-60 m-b-20 m-t-40">
            <div className={styles.container}>
              <div className={styles.textCol}>
                <h1 className="fs-38 m-b-50">{translate(title)}</h1>
                <div className="fs-20 m-b-30">{translate(description)}</div>
                <div>
                  {isInsideLink ? (
                    <Button
                      type="button"
                      to={buttonLink}
                      variation="primary"
                      size="large"
                      className={styles.connectButton}
                    >
                      {translate(buttonText)}
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      href={buttonLink}
                      variation="primary"
                      size="large"
                      className={styles.connectButton}
                    >
                      {translate(buttonText)}
                    </Button>
                  )}

                  {getContactContent(option)}
                </div>
              </div>
              <div className={styles.imageCol}>
                <img src={image} alt="site example" />
              </div>
            </div>
          </Panel>
        </Container>
      </div>
    )
  )
}

ServiceUpsell.propTypes = {
  service: PropTypes.string.isRequired,
  serviceEligibility: PropTypes.bool.isRequired,
  wiShippingEnabled: PropTypes.bool.isRequired,
  wiReturnsEnabled: PropTypes.bool.isRequired,
  isReturnsEligible: PropTypes.bool.isRequired,
  isShippingEligible: PropTypes.bool.isRequired,
}

export default withRouter(ServiceUpsell)
