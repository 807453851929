import React, { Component } from "react"
import PropTypes from "prop-types"
import { Switch, Route, Redirect } from "react-router"
import { connect } from "react-redux"
import Loadable from "react-loadable"
import { HOC as Permissions } from "react-redux-permissions"

import ModuleDisabled from "shared/views/NotAllowed/ModuleDisabled"
import Loader from "shared/components/Loader/Local"

import DashboardDisabled from "./Dashboard/Disabled"
import Tools from "./Tools"

import {
  setActivePlatform,
  setActiveAccount,
} from "../data/actions/user/general"
import { validAccounts } from "../data/selectors/user"

const Dashboard = Loadable({
  loader: () => import("./Dashboard"),
  loading: () => <Loader />,
})
const Products = Loadable({
  loader: () => import("./Products"),
  loading: () => <Loader />,
})
const Orders = Loadable({
  loader: () => import("./Orders"),
  loading: () => <Loader />,
})

const CdiscountNewAccount = Loadable({
  loader: () => import("./NewAccount/Cdiscount"),
  loading: () => <Loader />,
})

const NewAccountView = Loadable({
  loader: () => import("./NewAccount/NewAccountView"),
  loading: () => <Loader />,
})

const EbayMarketing = Loadable({
  loader: () => import("./EbayMarketing"),
  loading: () => <Loader />,
})

const DashboardView = Permissions(undefined, ["preTrialESB"])(
  Dashboard,
  <DashboardDisabled />
)

const ProductsView = Permissions(
  ["paying", "passive", "trial"],
  ["preTrialESB"]
)(
  Products,
  <ModuleDisabled className={["m-t-30", "m-b-30"].join(" ")} view="Products" />
)

const OrdersView = Permissions(
  ["paying", "trial", "trialESB"],
  ["preTrialESB"]
)(
  Orders,
  <ModuleDisabled className={["m-t-30", "m-b-30"].join(" ")} view="Orders" />
)

const CdiscountOnboardingView = Permissions(
  ["cdiscountEnabled"],
  ["preTrialESB"]
)(
  CdiscountNewAccount,
  <ModuleDisabled className={["m-t-30", "m-b-30"].join(" ")} view="Cdiscount" />
)

const OnboardingView = Permissions(undefined, ["preTrialESB"])(
  NewAccountView,
  <ModuleDisabled
    className={["m-t-30", "m-b-30"].join(" ")}
    view="Onboarding"
  />
)

const EbayMarketingView = Permissions(
  ["paying", "trial", "trialESB"],
  ["preTrialESB"]
)(
  EbayMarketing,
  <ModuleDisabled
    className={["m-t-30", "m-b-30"].join(" ")}
    view="Ebay Promoted Listings"
  />
)

class Root extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    activePlatform: PropTypes.string.isRequired,
    activeAccount: PropTypes.any,
    setPlatform: PropTypes.func.isRequired,
    setAccount: PropTypes.func.isRequired,
    accounts: PropTypes.object.isRequired,
  }

  static defaultProps = {
    activeAccount: null,
  }

  constructor(props) {
    super(props)
    this.setPlatform()
    this.setAccount()
  }

  componentDidUpdate() {
    this.setPlatform()
    this.setAccount()
  }

  setPlatform() {
    const { activePlatform, setPlatform, match } = this.props
    const paramsPlatform = match.params.platform

    if (activePlatform === paramsPlatform) return
    if (paramsPlatform === "all") return

    setPlatform(paramsPlatform)
  }

  setAccount() {
    const { history, match, accounts, activeAccount, setAccount } = this.props
    const paramsPlatform = match.params.platform
    let urlAccount = match.params.account
    if (["all", "ebay", "amazon", "cdiscount"].includes(paramsPlatform)) {
      urlAccount = parseInt(urlAccount, 10)
    }
    if (activeAccount === urlAccount) return
    if (accounts[paramsPlatform].indexOf(urlAccount) === -1) {
      history.replace(
        `/${paramsPlatform}/${accounts[paramsPlatform][0]}/dashboard`
      )
    } else {
      setAccount(urlAccount)
    }
  }

  render() {
    const { match } = this.props

    return (
      <Switch>
        <Route path="/:platform/:account/dashboard" component={DashboardView} />
        <Route
          path="/cdiscount/:account/onboarding"
          component={CdiscountOnboardingView}
        />
        <Route
          path="/:platform(amazon|ebay)/:account/onboarding"
          component={OnboardingView}
        />
        <Route path="/:platform/:account/products" component={ProductsView} />
        <Route path="/:platform/:account/orders" component={OrdersView} />
        <Route path="/:platform/:account/tools" component={Tools} />
        <Route
          path="/:platform/:account/marketing"
          component={EbayMarketingView}
        />
        <Redirect
          from="/"
          to={`/${match.params.platform}/${match.params.account}/dashboard`}
        />
      </Switch>
    )
  }
}

const mapStateToProps = state => ({
  activePlatform: state.general.activePlatform,
  activeAccount: state.general.activeAccount,
  accounts: validAccounts(state),
})

const mapDispatchToProps = dispatch => ({
  setPlatform: platform => dispatch(setActivePlatform(platform)),
  setAccount: account => dispatch(setActiveAccount(account)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Root)
