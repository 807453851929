import React from "react"
import { Switch, Route, Redirect } from "react-router"
import Loadable from "react-loadable"
import { HOC as PermissionsHOC } from "react-redux-permissions"

import ModuleDisabled from "shared/views/NotAllowed/ModuleDisabled"
import Loader from "shared/components/Loader/Local"

const Blacklist = Loadable({
  loader: () => import("./views/Blacklist"),
  loading: () => <Loader />,
})
const BulkRequest = Loadable({
  loader: () => import("./views/BulkRequest"),
  loading: () => <Loader />,
})
const ConversionCharts = Loadable({
  loader: () => import("./views/ConversionCharts"),
  loading: () => <Loader />,
})
const PriceAdjustment = Loadable({
  loader: () => import("./views/PriceAdjustment"),
  loading: () => <Loader />,
})
const Repricer = Loadable({
  loader: () => import("./views/Repricer"),
  loading: () => <Loader />,
})
const ShippingManager = Loadable({
  loader: () => import("./views/ShippingManager"),
  loading: () => <Loader />,
})
const TranslationManager = Loadable({
  loader: () => import("./views/TranslationManager"),
  loading: () => <Loader />,
})

const BlacklistView = PermissionsHOC(["blacklist"])(
  Blacklist,
  <ModuleDisabled className={["m-t-80", "m-b-30"].join(" ")} view="Blacklist" />
)

const RepricerView = PermissionsHOC(["amazon"])(
  Repricer,
  <ModuleDisabled className={["m-t-80", "m-b-30"].join(" ")} view="Repricer" />
)

const BulkRequestView = PermissionsHOC(["bulkItemRequest"])(
  BulkRequest,
  <ModuleDisabled
    className={["m-t-80", "m-b-30"].join(" ")}
    view="Bulk request"
  />
)

const Tools = () => (
  <Switch>
    <Route
      path="/:platform/:account/tools/blacklist"
      component={BlacklistView}
    />
    <Route
      path="/:platform/:account/tools/bulk-request"
      component={BulkRequestView}
    />
    <Route
      path="/:platform/:account/tools/conversion-charts"
      component={ConversionCharts}
    />
    <Route
      path="/:platform/:account/tools/price-adjustment"
      component={PriceAdjustment}
    />
    <Route path="/:platform/:account/tools/repricer" component={RepricerView} />
    <Route
      path="/:platform/:account/tools/shipping-manager"
      component={ShippingManager}
    />
    <Route
      path="/:platform/:account/tools/translation-manager"
      component={TranslationManager}
    />
    <Redirect to="/dashboard" />
  </Switch>
)

export default Tools
