import {
  all,
  fork,
  putResolve,
  take,
  takeLatest,
  put,
  select,
} from "redux-saga/effects"

import {
  EPL_LOAD,
  EPL_LOAD_SUCCESS,
  EPL_LOAD_FAILURE,
  USER_GET_SUCCESS,
  EPL_REPORTS_GET,
  EPL_REPORTS_GET_SUCCESS,
  EPL_REPORTS_GET_FAILURE,
  EPL_NON_CRITICAL_FAILURE,
  EPL_SET_AD_RATE_FOR_MARKET_PATCH_SUCCESS,
  GENERAL_SET_ACTIVE_ACCOUNT,
} from "data/constants"

import {
  getAvailableSites,
  getUserOnboarding,
  getTermsStatus,
  getOptOutStatus,
  getCampaignReports,
  getAggregatedCampaignReport,
  getCampaignChartData,
  getHadPromotedItems,
  getMarkets,
  getAllMarkets,
  getMissingCampaignReports,
  getAccounts,
} from "data/actions/epl/epl"

import { getChannels } from "data/actions/zendesk/zendesk"

function* getInitialData() {
  yield take([USER_GET_SUCCESS])
}

function* getNonCriticalData() {
  try {
    yield put(getHadPromotedItems())
    yield put(getChannels())
  } catch (error) {
    yield put({ type: EPL_NON_CRITICAL_FAILURE })
  }
}

function* reloadMarkets() {
  yield putResolve(getMarkets())
}

function* loadViewData() {
  try {
    yield getNonCriticalData()
    yield putResolve(getAvailableSites())
    yield putResolve(getAccounts())
    yield all([
      putResolve(getMarkets()),
      putResolve(getAllMarkets()),
      putResolve(getTermsStatus()),
      putResolve(getUserOnboarding()),
      putResolve(getOptOutStatus()),
    ])

    yield put({ type: EPL_LOAD_SUCCESS })
  } catch (error) {
    if ([424, 500, 502, 504].includes(error.status)) {
      yield put({ type: EPL_LOAD_FAILURE })
    } else {
      yield put({ type: EPL_LOAD_SUCCESS })
    }
  }
}

function* fetchReports(action) {
  try {
    const { params } = action
    const { user } = yield select()
    const formattedParams = {
      currency: user.currency,
      date_from: params.dateFrom,
      date_to: params.dateTo,
      sites: params.sites,
      state_filter: params.state,
      origin: params.origin,
      model: params.model,
    }
    yield all([
      putResolve(getCampaignReports(formattedParams)),
      putResolve(getAggregatedCampaignReport(formattedParams)),
      putResolve(getCampaignChartData(formattedParams)),
    ])
    yield put({ type: EPL_REPORTS_GET_SUCCESS })
  } catch (error) {
    yield put({ type: EPL_REPORTS_GET_FAILURE })
  }
}

function* fetchMissingReports(action) {
  try {
    const { params } = action
    const formattedParams = {
      date_from: params.dateFrom,
      date_to: params.dateTo,
      sites: params.sites,
      state_filter: params.state,
      origin: params.origin,
      model: params.model,
    }
    yield putResolve(getMissingCampaignReports(formattedParams))
  } catch {
    yield put({ type: EPL_NON_CRITICAL_FAILURE })
  }
}

export default function* main() {
  const tasks = yield all([fork(getInitialData)])
  yield all(tasks)
  yield takeLatest([EPL_LOAD, GENERAL_SET_ACTIVE_ACCOUNT], loadViewData)
  yield takeLatest(EPL_REPORTS_GET, fetchReports)
  yield takeLatest(EPL_REPORTS_GET, fetchMissingReports)
  yield takeLatest(EPL_SET_AD_RATE_FOR_MARKET_PATCH_SUCCESS, reloadMarkets)
}
