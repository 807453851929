import React, { Component } from "react"
import PropTypes from "prop-types"
import { Redirect } from "react-router"
import { connect } from "react-redux"
import { keys } from "lodash"

import { validAccounts } from "../data/selectors/user"

class FallbackComponent extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    activePlatform: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    platforms: PropTypes.arrayOf(PropTypes.string).isRequired,
    accounts: PropTypes.shape({
      all: PropTypes.arrayOf(PropTypes.number).isRequired,
      amazon: PropTypes.arrayOf(PropTypes.number).isRequired,
      ebay: PropTypes.arrayOf(PropTypes.number).isRequired,
      cdiscount: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
  }
  constructor(props) {
    super(props)

    this.fallbackRoutes = {
      "/dashboard/:platform?": "/{platform}/{account}/dashboard",
      "/products/:platform?/:tab?": "/{platform}/{account}/products/{tab}",
      "/orders/:platform?": "/{platform}/{account}/orders",
      "/tools/conversion-charts":
        "/{all}/{account}/tools/conversion-charts/default",
      "/tools/translation-manager":
        "/{all}/{account}/tools/translation-manager",
      "/tools/blacklist/:platform?": "/{platform}/{account}/tools/blacklist",
      "/tools/price-adjustment/:platform?":
        "/{platform}/{account}/tools/price-adjustment",
      "/tools/shipping-manager": "/ebay/{account}/tools/shipping-manager",
      "/tools/bulk-request": "/ebay/{account}/tools/bulk-request",
      "/tools/repricer": "/amazon/{account}/tools/repricer",
      "/profile/accounts": "/user/accounts",
      "/profile": "/user",
      "/plans": "/user/plans",
      "/ebay-promoted-listings": "/ebay/{account}/marketing",
      "/ebay-promoted-listings-settings": "/ebay/{account}/marketing/settings",
      "/cdiscount-dashboard": "/cdiscount/{account}/dashboard",
      "/cdiscount-onboarding": "/cdiscount/{account}/onboarding",
      "/amazon-onboarding": "/amazon/{account}/onboarding",
      "/ebay-onboarding": "/ebay/{account}/onboarding",
    }
  }

  getRedirectPath() {
    const { match, location } = this.props
    let newPath = this.fallbackRoutes[match.path] || "/"
    newPath = this.replacePlatformInPath(newPath)
    newPath = this.replaceAccountInPath(newPath)
    newPath = this.replaceTabInPath(newPath)
    return `${newPath}${location.search}`
  }

  replacePlatformInPath(path) {
    const { match, activePlatform, platforms } = this.props
    let newPath
    if (
      match.params.platform &&
      platforms.indexOf(match.params.platform) > -1
    ) {
      newPath = path.replace("{platform}", match.params.platform)
      newPath = newPath.replace("{all}", match.params.platform)
    } else {
      newPath = path.replace("{platform}", activePlatform || platforms[0])
      newPath = newPath.replace("{all}", "all")
    }

    return newPath
  }

  replaceAccountInPath(path) {
    const { accounts, userId } = this.props
    let account = userId

    if (path.indexOf("ebay") > -1) {
      account = accounts.ebay[0] // eslint-disable-line
    }

    return path.replace("{account}", account)
  }

  replaceTabInPath(path) {
    const { match } = this.props
    const tab = match.params.tab || "all"
    return path.replace("{tab}", tab)
  }

  render() {
    const path = this.getRedirectPath()
    return <Redirect to={path} />
  }
}

const mapStateToProps = state => ({
  userId: state.user.id,
  activePlatform: state.general.activePlatform,
  platforms: keys(state.user.platforms),
  accounts: validAccounts(state),
})

export default connect(mapStateToProps)(FallbackComponent)
