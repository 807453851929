import { useEffect } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { get } from "lodash"

import { dispatchTokenStateConnection } from "data/actions/user/multichannelOnboarding"

const TokenConnectionHandler = ({ location, dispatchRedirectReason }) => {
  const { query } = location

  useEffect(() => {
    const onboardingPlatform = get(query, "onboarding", "")
    const reason = get(query, "fail_type", "")

    if (["amazon", "ebay"].includes(onboardingPlatform)) {
      dispatchRedirectReason(onboardingPlatform, reason)
    }

    return undefined
  }, [])

  return null
}

TokenConnectionHandler.propTypes = {
  dispatchRedirectReason: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  dispatchRedirectReason: (platform, reason) =>
    dispatch(dispatchTokenStateConnection(platform, reason)),
})

export default connect(
  null,
  mapDispatchToProps
)(TokenConnectionHandler)
